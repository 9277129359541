<template>
  <main
    class="offer tw-h-full tw-flex tw-flex-col"
  >
    <div
      v-if="getCurrentOffer"
      class="tw-h-full tw-flex-1 tw-flex tw-flex-col md:tw-flex-row"
    >
      <div
        class="map-content tw-relative md:tw-w-1/2 tw-h-full md:tw-flex-1"
      >
        <offer-detail-map
          :has-zoom-controls="true"
          :has-scroll-wheel-zoom="true"
          class="tw-h-full"
          data-test="map"
        />

        <offer-travel
          v-if="travel.distance && travel.duration"
          :distance="travel.distance"
          :duration="travel.duration"
          class="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-m-auto tw-mt-5"
        />
      </div>

      <div
        class="md:tw-w-1/2 tw-overflow-y-auto"
      >
        <offer-detail
          :offer="getCurrentOffer"
          :has-map="false"
        />
      </div>
    </div>

    <div
      v-else
      class="tw-container tw-mx-auto"
    >
      <offer-not-found
        data-test="not-found"
      />
    </div>
  </main>
</template>

<script>
  import { computed, defineComponent, onMounted, reactive } from '@vue/composition-api'

  import OfferDetailMap from '@/components/OfferDetailMap/index.vue'
  import OfferDetail from '@/views/Carriers/Offers/components/OfferDetail/index.vue'
  import OfferTravel from '@/views/Carriers/Offers/components/OfferDetail/components/OfferMapSection/_subs/OfferTravel/index.vue'
  import OfferNotFound from '@/views/Carriers/Offers/components/OfferDetail/components/OfferNotFound/index.vue'
  import useRoute from '@/composables/useRoute'
  import useWait from '@/composables/useWait'
  import useStore from '@/composables/useStore'
  import useRouter from '@/composables/useRouter'

  /**
   * @module view - Offer
   */
  export default defineComponent({
    name: 'Offer',
    components: {
      OfferDetailMap,
      OfferDetail,
      OfferTravel,
      OfferNotFound
    },
    setup () {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const wait = useWait()

      const travel = reactive({
        duration: null,
        distance: null
      })

      const getCurrentOffer = computed(() => store.value.getters['offers/getCurrentOffer'])

      function fetchOffer () {
        const { uuid } = route.value.params

        wait.start(`fetching offer ${uuid}`)
        store.value.dispatch('setAppReady', false)
        store.value.dispatch('offers/retrieveOffer', uuid)
          .then(response => {
            const { distance, duration } = response.data.travel

            travel.distance = distance
            travel.duration = duration

            store.value.dispatch('offers/trackOffer', uuid)
          })
          .catch(err => {
            if (!err.response) return

            const { status } = err.response
            if (status === 404) {
              /**
               * A dialog will be presented to the user to inform that the offer
               * has already been booked.
               */
              setTimeout(() => {
                if (route.value.name !== 'Offer') return

                router.value.push({
                  name: 'Offers'
                })
                  .catch(() => {})

                store.value.dispatch('offers/resetCurrentOffer')
              }, 30000)
            }
          })
          .finally(() => {
            store.value.dispatch('setAppReady', true)
            wait.end(`fetching offer ${uuid}`)
          })
      }

      onMounted(() => {
        fetchOffer()
      })

      return {
        travel,
        getCurrentOffer
      }
    }
  })
</script>

<style lang="scss" scoped>
.offer .map-content {
  height: 250px;
}
@media (min-width: 770px) {
  .offer .offer-detail-map, .offer .map-content {
    height: 100%;
  }
}
.offer .offer-travel {
  max-width: 280px;
}
</style>
