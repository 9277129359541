<template>
  <map-offer />
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import MapOffer from './_subs/MapOffer/index.vue'

  import store from '@/store'

  /**
   * @module view - Offer
   */
  export default defineComponent({
    name: 'Offer',
    components: {
      MapOffer
    },
    metaInfo () {
      return {
        title: this.$t('offers.title')
      }
    },
    // @ts-ignore
    beforeRouteEnter (to, from, next) {
      if (store.getters['auth/isAccountLocked']) {
        next({
          name: 'MissionsRouter'
        })
        return
      }

      if (store.getters.isUserShipper) {
        next({
          name: 'Dashboard'
        })
        return
      }

      /**
       * Here we would like to save the user's previous route when
       * entering to the offer view in the store.
       * We need to know if the previous route was the offer search
       * route to change the wording accordingly in the back button.
       */
      if (from) {
        store.dispatch('offers/setOfferPreviousRoute', {
          name: from.name,
          params: from.params
        })
      }

      store.dispatch('setAppReady', true)

      next()
    },
    // @ts-ignore
    beforeRouteLeave (to, from, next) {
      store.dispatch('setAppReady', true)
      next()
    }
  })
</script>
